<template>
    <div class="getting-started">
        <div class="banner">
            <div class="banner-text">Getting Started</div>
            <img src="@/assets/banners/getting-started_banner.jpg"/>
        </div>
        <b-row class="home-content">
            <b-col>
                <p>
                    To participate, a sponsoring signatory contractor selects an employee to enter the program. This person
                    must be a Journeyman member in good standing with the UBC. <strong>The trainee must have an OSHA 30
                    Construction wallet card in TRAIN or Canadian Work Ready for Dispatch (NCC) in TRAIN, the UBC website that tracks training.</strong>
                    The signatory contractor also selects a mentor from the company, someone who can assist the trainee in
                    learning the new position. Trainees attend four sessions at the Carpenters International Training Center
                    (CITC) over an 18-month period, on Thursday through Saturday. Mentors attend a workshop during Programs 1
                    and 3, and then host the trainee for several mentoring sessions throughout the training period. The mentor
                    class is from 12:45 pm - 4:30 pm Friday, and 8 am - 3:35 pm Saturday.
                </p>
                <ul>
                    <li>18-month program</li>
                    <li>12 days of training over 4 three-day programs at the Carpenters International Training Center</li>
                    <li>15 (minimum) mentoring sessions between the trainee and the mentor</li>
                    <li>30 (minimum) specific on-the-job training exercises are selected by the mentor</li>
                </ul>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <h1>Eligibility Requirements to Apply</h1>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <ul>
                    <li>UBC Journeyman member in good standing.</li>
                    <li>Employed and designated by a signatory contractor.</li>
                    <li>Have a mentor from the contractor willing to work with them throughout the 18-month program.</li>
                    <li>OSHA 30 Construction wallet card in TRAIN or Canadian Work Ready for Dispatch.</li>
                    <li>Mentor must hold title higher than Foreman.</li>
                </ul>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                To apply to SCT, please <b-link to="enrollment">CLICK HERE</b-link>
                to enter your UBC ID and continue to complete the application form.
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <h1>Schedule</h1>
                <hr/>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <ul class="program-dates">
                    <template v-for="scheduleDate in scheduleDates">
                        <li v-for="date in scheduleDate.dates">
                            {{ date | datesText(scheduleDate.year) }}
                        </li>
                    </template>
                </ul>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/store';
import {addYears} from 'date-fns';
import formatters from '@/util/formatters';
import _ from 'underscore';
import {errorToastOptions} from "../../util/formatters";

@Component({
    filters: {
        datesText: (dates, year) => {
            return sprintf('%s - %s, %d',
                formatters.date(dates.start, 'MMM d'),
                formatters.date(dates.end, 'd'),
                year
            );
        }
    }
})
export default class GettingStarted extends Vue {

    ubcId = null;


    get scheduleDates() {

        const now = new Date();
        const nextYear = addYears(now, 1);
        const years = [now.getFullYear(), nextYear.getFullYear()];

        const seen = {};

        return _.map(years, (year) => {
            return {
                year,
                dates: _.chain(this.$store.getters['tracks/getProgramsByYear'](year))
                    .filter((p) => p.idx === 1)
                    .map((p) => {
                        return {
                            start: p.dates[0],
                            end: p.dates[1]
                        };
                    })
                    .filter((p) => {
                        const key = formatters.date(p.start);
                        const hasBeenSeen = _.has(seen, key);

                        if (hasBeenSeen) {
                            return false;
                        }

                        seen[key] = true;
                        return true;

                    })
                    .sortBy('start')
                    .value()
            };
        });
    }

    async beforeRouteEnter(to, from, next) {
        try {
            await store.dispatch('tracks/loadTracks');
        }
        catch (error) {
            const vm = new Vue();
            vm.$bvToast.toast(error.message, errorToastOptions);
        }
        next();
    }
}
</script>
<style scoped>

</style>
